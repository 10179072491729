import { useCallback, useRef, useState } from 'react'
import { FiGlobe } from 'react-icons/fi'
import { SupportedLocale, SUPPORTED_LOCALES, SwapWidget } from '@uniswap/widgets'

// ↓↓↓ Don't forget to import the widgets' fonts! ↓↓↓
import '@uniswap/widgets/fonts.css'
// ↑↑↑

import { useActiveProvider } from '../connectors'
import { JSON_RPC_URL } from '../constants'
import DocumentationCards from './DocumentationCards'
import Web3Connectors from './Web3Connectors'
import styles from '../styles/Home.module.css'

// const TOKEN_LIST = 'https://gateway.ipfs.io/ipns/tokens.uniswap.org'
// You can also pass a token list as JSON, as long as it matches the schema
const MY_TOKEN_LIST = [
  {
    "name": "Testnet Flip",
    "address": "0x1194c91d47fc1b65be18db38380b5344682b67db",
    "symbol": "tFLIP",
    "decimals": 18,
    "chainId": 5,
    "logoURI": "https://storage.googleapis.com/job-listing-logos/251080f8-2fd6-4332-8e2d-f1dccb787054.png"
  },
  {
    "name": "Wrapped gETH",
    "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    "symbol": "wGETH",
    "decimals": 18,
    "chainId": 5,
    "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"
  }
]

const tFLIP = "0x1194c91d47fc1b65be18db38380b5344682b67db"

export default function App() {
  // When a user clicks "Connect your wallet" in the SwapWidget, this callback focuses the connectors.
  const connectors = useRef<HTMLDivElement>(null)
  const focusConnectors = useCallback(() => connectors.current?.focus(), [])

  // The provider to pass to the SwapWidget.
  // This is a Web3Provider (from @ethersproject) supplied by @web3-react; see ./connectors.ts.
  const provider = useActiveProvider()

  // The locale to pass to the SwapWidget.
  // This is a value from the SUPPORTED_LOCALES exported by @uniswap/widgets.
  const [locale, setLocale] = useState<SupportedLocale>('en-US')
  const onSelectLocale = useCallback((e) => setLocale(e.target.value), [])

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <h1 className={styles.title}>tFLIP Marketplace</h1>
        <p>Are you aping anon?</p>

        <div className={styles.demo}>

          <div className={styles.widget}>
            <SwapWidget
              jsonRpcUrlMap={{ 5: JSON_RPC_URL }}
              tokenList={MY_TOKEN_LIST}
              provider={provider}
              locale={locale}
              // onConnectWallet={focusConnectors}
              defaultInputTokenAddress="NATIVE"
              defaultInputAmount="1"
              defaultOutputTokenAddress={tFLIP}
              defaultChainId={5}
            />
          </div>
        </div>
          <div style={{padding:'100px', textAlign:'center'}}>
            <h4 style={{fontWeight:"normal"}}>made by</h4>
            <a target="_blank" href="https://thunderhead.world">
            <img src="/thunderhead.png" width="200px"/>
            </a>
          </div>
      </main>
    </div>
  )
}
